import { editableInputTypes } from '@testing-library/user-event/dist/utils';
import currency from 'currency.js';
import React, { useEffect, useState } from 'react';
import AddMember from './AddMember';
import EditMember from './EditMember';
import TitheList from './TitheList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';


//ADD PAYPAL SUPPORT AND AUTO LOAD TITHES INTO LIST 
//IF DONATION MADE OTHER THAN SUNDAY ADD TO NEAREST SUNDAY?


function Form() {
  const [members, setMembers] = useState([{}]);
  const [update, setUpdate] = useState(false);
  const [titheList, setTitheList] = useState([{}]);
  const url = 'https://church-tithes-backend.onrender.com/members/';
  //const url = 'http://localhost:27017/members/';

  const refresh = () => {
    setUpdate(true);
    if (update == true) {
      setUpdate(false);
    }
  };

  //Fetch the member data from collection
  const fetchMemberData = async () => {
    try {
      const response = await fetch(url, {
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        })
      });
      const data = await response.json();

      //Sort by last name then set member state data
      data.sort((a, b) => (a.lastName > b.lastName) ? 1 : -1)
      setMembers(data);

    } catch (error) {
      console.log(error);
    }
  };

  //Delete the selected member
  const deleteMember = async () => {
    try {
      let decision = window.confirm('Are you sure you want to delete this member?');
      if (decision) {
        let selectList = document.getElementById("members-list");
        let selectedName = selectList.options[selectList.selectedIndex].text;
        let selectedNameArray = selectedName.split(",");
        const response = await fetch(url + 'delete', {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }),
          body: JSON.stringify({firstName: selectedNameArray[1].trim(), lastName: selectedNameArray[0]})
        })
        const data = await response.json();
        // setUpdate(true);
        refresh();  
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editName = () => {
    let editFirstName = document.getElementById("edit-first-name");
    let editLastName = document.getElementById("edit-last-name");
    let selectList = document.getElementById("members-list");
    let selectedName = selectList.options[selectList.selectedIndex].text;
    let selectedNameArray = selectedName.split(",");

    editFirstName.value = selectedNameArray[1].trim();
    editLastName.value = selectedNameArray[0];

    selectList.addEventListener('change', () => {
      let selectList = document.getElementById("members-list");
      let selectedName = selectList.options[selectList.selectedIndex].text;
      let selectedNameArray = selectedName.split(",");
      editFirstName.value = selectedNameArray[1].trim();
      editLastName.value = selectedNameArray[0];
    });
  }

  const toggleElement = (id) => {
    switch (id) {
      case 'add-member-container':
        if (document.getElementById('edit-member-container').classList.contains('show')) {
          document.getElementById('edit-member-container').classList.toggle('show');
        }
        document.getElementById(id).classList.toggle('show');
        break;
      case 'edit-member-container':
        if (document.getElementById('add-member-container').classList.contains('show')) {
          document.getElementById('add-member-container').classList.toggle('show');
        }
        document.getElementById(id).classList.toggle('show');
        break;
      default:
        document.getElementById(id).classList.toggle('show');
        break;
    }
  }

  //Runs on update and on changes to new member status state data
  useEffect(() => {
    fetchMemberData();
  }, [update]);

  //Runs on page load
  //Format the date in the datepicker box
  const formatDate = () => {
    let date = new Date();
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  return (
    <div class="form-container">
      <div class="add-edit-container">
        
        {/* <button id="delete-span" onClick={deleteMember}>Delete Member</button> */}
      </div>
      <input id="date-field" type="date" defaultValue={formatDate()}/>
      <div class="member-list-container">
        <select id="members-list">
        {members.map((each, index) => {
          let eachString = each.lastName + ', ' + each.firstName;
          return(<option key={index}>{eachString}</option>);
        })}
        </select>
        <FontAwesomeIcon icon={faPlus} onClick={() => toggleElement('add-member-container')} />
        <FontAwesomeIcon icon={faPenToSquare} id="edit-span" onClick={() => {toggleElement('edit-member-container'); editName()}}/>
        <AddMember refresh={refresh} />
        <EditMember refresh={refresh} />
      </div>
      <input id="amount" placeholder="amount" type="number" inputmode="decimal"/>
      <TitheList refresh={refresh} />
    </div>
  )
}
export default Form;
