import React, { useEffect, useState } from 'react';

function EditMember(props) {
  const url = 'https://church-tithes-backend.onrender.com/members/edit';
  // const url = 'http://localhost:27017/members/edit';

  const editMember = async () => {
    let editFirstName = document.getElementById("edit-first-name");
    let editLastName = document.getElementById("edit-last-name");
    let editName = editLastName.value + ', ' + editFirstName.value;
    let selectList = document.getElementById("members-list");
    let selectedName = selectList.options[selectList.selectedIndex].text;
    let selectedNameArray = selectedName.split(",");

    if ((editFirstName.value == '') || (editLastName.value == '')) {
      console.log('one field is blank');
      return;
    }

    if (selectedName == editName) {
      console.log('names cannot be the same');
      return;
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }),
        body: JSON.stringify({
          firstName: selectedNameArray[1].trim(),
          lastName: selectedNameArray[0],
          editFirstName: editFirstName.value,
          editLastName: editLastName.value
        })
      });
      props.refresh();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div id="edit-member-container">
      <input id="edit-first-name" />
      <input id="edit-last-name" />
      <button id="edit-submit" onClick={editMember}>save</button>
    </div>
  )
}

export default EditMember;
