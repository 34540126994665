import currency from 'currency.js';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

function TitheList(props) {
  const [titheList, setTitheList] = useState(undefined);
  const [updateList, setUpdateList] = useState(false);
  const [titheTotal, setTitheTotal] = useState(0);
  const [saveMessage, setSaveMessage] = useState('');
  const url = 'https://church-tithes-backend.onrender.com/tithes/save';
  // const url = 'http://localhost:27017/tithes/save';

  const refresh = () => {
    setUpdateList(true);
    if (updateList == true) {
      setUpdateList(false);
    }
  }

  const addToTitheList = () => {
    try {
      let selectList = document.getElementById("members-list");
      let selectedName = selectList.options[selectList.selectedIndex].text;
      let amount = currency(document.getElementById("amount").value);
      
      if (titheList == undefined && amount.value !== 0) {
        setTitheList([{name: selectedName, amount: amount}])
        setTitheTotal(amount);
      }
      else if (amount.value !== 0) {
        setTitheList([...titheList, {name: selectedName, amount: amount}])
        setTitheTotal(currency(titheTotal).add(amount).value);
      }
      else {
        console.log('error with input');
      }

      document.getElementById('amount').value = '';

    } catch (error) {
      console.log(error);
    }
  }

  const deleteFromList = (listIndex) => {
    try {
      setTitheTotal(currency(titheTotal).subtract(titheList[listIndex].amount));
      if (titheList.length == 1) {
        setTitheList(undefined)
      } else {
        setTitheList(titheList.filter((each, index) => index !== listIndex))
      }
    } catch (error) {
      console.log(error);
    }
  }

  const saveTithes = async () => {
    try {
      if (titheList == undefined) {
        return console.log('List was not saved')
      }
      let date = document.getElementById('date-field').value;
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }),
        body: JSON.stringify({date: date, tithes: titheList, total: titheTotal})
      })
      if (response.status == 200) {
        setSaveMessage('Data saved successfully');
        setTimeout(() => {
          setSaveMessage('');
        }, 3000)
      } else {
        setSaveMessage(response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const titheListHTML = () => {
    if (titheList !== undefined) {
      return (
        titheList.map((each, index) => {  
          let eachString = each.name + ': ' + currency(each.amount).format();
          return (
            <div class='each-container'>
              <span class="each-tithe" key={index}>{eachString}</span>
              <FontAwesomeIcon icon={faCircleXmark} class="delete-each" onClick={() => deleteFromList(index)} />
            </div>
          )
        })
      )   
    }
  }

  const clearForm = () => {
    document.getElementById('amount').value = '';
    setTitheList(undefined);
    setTitheTotal(currency(0))
  }

  //Runs on update and on changes to new member status state data
  useEffect(() => {
    addToTitheList();
  }, [updateList]);

  return (
    <div id="tithe-list">
      <button id="add-to-list-button" onClick={refresh}>add</button>
      <button id="save-tithes" onClick={saveTithes}>save</button>
      <button onClick={clearForm}>clear</button>
      <div id="save-message"><span>{saveMessage}</span></div>
      <div><h1>{titheTotal !== undefined ? currency(titheTotal).format() : '$0.00'}</h1></div>
      {titheListHTML()}
    </div>
  )
}
export default TitheList;
