import './App.css';
import Form from './components/Form/Form';
import Report from './components/Reports/Report';

function App() {
  return (
    <>
    <Form />
    <Report />
    </>
  );
}

export default App;
