import { editableInputTypes } from '@testing-library/user-event/dist/utils';
import currency from 'currency.js';
import React, { useEffect, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
 
function Report() {
  const [showDates, setShowDates] = useState();
  const [showReport, setShowReport] = useState();
  const [update, setUpdate] = useState(false);

  const url = 'https://church-tithes-backend.onrender.com/tithes/';
  // const url = 'http://localhost:27017/tithes/';

  const refresh = () => {
    setUpdate(true);
    if (update == true) {
      setUpdate(false);
    }
  }

  const getTithes = async () => {
    const response = await fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    })
    const data = await response.json();
    data.sort((a, b) => (a.date > b.date) ? 1 : -1)
    return data;
  }

  const deleteTithe = async (id, event) => {
    console.log(id)
    const response = await fetch(url + '/delete', {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }),
      body: JSON.stringify({_id: id})
    })
    const data = await response.json();
    if (data.deletedCount == 1) {
      displayDates(event);
      displayReport();
    }
    console.log(data)
  }

  const displayDates = async (event) => {
    //LIST ALL AVAILABLE DAYS - TEXTBOX INPUT WITH CHANGE LISTENER FOR FILTERING
    let data = await getTithes();

    const Table = () => {
      let Row;
      let currentDate;
      let rowData = [];
      let rowTotal = 0;
      let rowTotalArray = [];
      switch(event.target.id) {
        case 'daily':
          Row = ({index, style}) => (
            <div style={style}>
              <a class="date-link">{data[index].date}</a>
              <a class="total-amount">{currency(data[index].total).format()}</a>
              <a id="view-tithe" onClick={() => displayReport(data[index], 'daily')}>view</a>
              {/* <a id="delete-tithe" onClick={() => deleteTithe(data[index]._id, event)}>Delete</a> */}
            </div>
          );
          return (
            <div class="daily-container">
              <List
                height={400}
                itemCount={data.length}
                itemSize={35}
                width={320}
              >
                {Row}
              </List>
            </div>
          )

        case 'monthly':
          let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
          data.sort((a, b) => ((a.date.substring(5,7) > b.date.substring(5,7)) && (a.date.substring(0,4) >= b.date.substring(0,4))) ? 1 : -1);
          let monthData = [];
          let monthDataList = [];

          for (let index = 0; index <= data.length; index++) {
            if (currentDate == undefined) {
              currentDate = data[index].date.substring(0,7);
              rowData.push(data[index]);
              monthData.push(data[index]);
              rowTotal += data[index].total;
            }
            else if (index == data.length) {
              monthDataList.push(monthData);
              rowTotalArray.push(rowTotal);
            }
            else if (currentDate == data[index].date.substring(0,7)) {
              monthData.push(data[index]);
              rowTotal += data[index].total;
            }
            else if (currentDate !== data[index].date.substring(0,7)) {
              currentDate = data[index].date.substring(0,7);
              rowData.push(data[index]);
              monthDataList.push(monthData);
              monthData = [];
              monthData.push(data[index]);
              rowTotalArray.push(rowTotal);
              rowTotal = 0;
              rowTotal += data[index].total;
            }
          }

          Row = ({index, style}) => {
            if (rowData[index].date.substring(5,6) == '0') {
              return (
                <div style={style}>
                  <a class="date-link">{rowData[index].date.substring(0,4) + ' ' + months[rowData[index].date.substring(6,7) - 1]}</a>
                  <span class="total-amount">{currency(rowTotalArray[index]).format()}</span>
                  <a id="view-tithe" onClick={() => displayReport(monthDataList[index], 'monthly')}>view</a>
                </div>
              );
            }
            else if (rowData[index].date.substring(5,6) == '1') {
              return (
                <div style={style}>
                  <a class="date-link">{rowData[index].date.substring(0,4) + ' ' + months[rowData[index].date.substring(5,7) - 1]}</a>
                  <span class="total-amount">{currency(rowTotalArray[index]).format()}</span>
                  <a id="view-tithe" onClick={() => displayReport(monthDataList[index], 'monthly')}>view</a>
                </div>
              );
            }
          };

          return (
            <div class="monthly-container">
              <List
                height={400}
                itemCount={rowData.length}
                itemSize={35}
                width={320}
              >
                {Row}
              </List>
            </div>
          )

        case 'yearly':
          data.sort((a, b) => ((a.date.substring(0,4) > b.date.substring(0,4))) ? 1 : -1);
          let yearData = [];
          let yearDataList = [];
          
          for (let index = 0; index <= data.length; index++) {
            if (currentDate == undefined) {
              currentDate = data[index].date.substring(0,4);
              rowData.push(data[index]);
              yearData.push(data[index]);
              rowTotal += data[index].total;
            }
            else if (index == data.length) {
              yearDataList.push(yearData);
              rowTotalArray.push(rowTotal);
            }
            else if (currentDate == data[index].date.substring(0,4)) {
              yearData.push(data[index]);
              rowTotal += data[index].total;
            }
            else if (currentDate !== data[index].date.substring(0,4)) {
              currentDate = data[index].date.substring(0,4);
              rowData.push(data[index]);
              yearDataList.push(yearData);
              yearData = [];
              yearData.push(data[index]);
              rowTotalArray.push(rowTotal);
              rowTotal = 0;
              rowTotal += data[index].total;
            }
          }

          Row = ({index, style}) => {
            return (
              <div style={style}>
                <a class="date-link">{rowData[index].date.substring(0,4)}</a>
                <span class="total-amount">{currency(rowTotalArray[index]).format()}</span>
                <a id="view-tithe" onClick={() => displayReport(yearDataList[index], 'yearly')}>view</a>
              </div>
            );
          };

          return (
            <div class="yearly-container">
              <List
                height={100}
                itemCount={rowData.length}
                itemSize={35}
                width={320}
              >
                {Row}
              </List>
            </div>
          )

        default:
          return <></>

      }
    }
    setShowDates(<Table/>)
  }

  const displayReport = (data, type) => {
    const Table = () => {
      let Row;
      
        //NEED TO CHECK IF DATA IS AN OBJECT OR LIST OF OBJECTS 
        //SINGLE DAY WILL BE AN OBJECT, MONTH/YEAR WILL BE LIST
        //MAP IS AN ARRAY METHOD - CANNOT BE USED ON SINGLE DAY DATA
        //CLEAN THIS CODE UP
        let memberTotals = [];

        switch(type) {
          case 'daily':
            data.tithes.map(member => {
              let findObj = memberTotals.find(result => result.name == member.name);
              if (findObj) {
                memberTotals.find((found, index) => {
                  if (found.name == member.name) {
                    memberTotals[index] = {name: member.name, amount: memberTotals[index].amount + member.amount}
                  }
                });
              } else {
                memberTotals.push(member)
              }
            });
            break;

          case 'monthly':
          case 'yearly':
            data.map((each, index) => {
              each.tithes.map(member => {
                let findObj = memberTotals.find(result => result.name == member.name);
                if (findObj) {
                  memberTotals.find((found, index) => {
                    if (found.name == member.name) {
                      memberTotals[index] = {name: member.name, amount: memberTotals[index].amount + member.amount}
                    }
                  });
                } else {
                  memberTotals.push(member)
                }
              });
            });
            break;
       }
       Row = ({index, style}) => (
        <div style={style}>
          <span id="report-name">{memberTotals[index].name}:</span>
          <span id="report-amount">{currency(memberTotals[index].amount).format()}</span>
        </div>
      );

      return (
        <List
          height={400}
          itemCount={memberTotals.length}
          itemSize={35}
          width={320}
        >
          {Row}
        </List>
      );
    }
    setShowReport(<Table/>);
  }
  
  return (
    <div class="report-container">
      <div class="button-wrapper">
        <button id="daily" onClick={displayDates}>daily</button>
        <button id="monthly" onClick={displayDates}>monthly</button>
        <button id="yearly" onClick={displayDates}>yearly</button>
      </div>
      <div class="report-layout-container">
        {showDates}
        {showReport}
      </div>
    </div>
  )
}
export default Report;
