import React, { useEffect, useState } from 'react';

function AddMember(props) {
  const url = 'https://church-tithes-backend.onrender.com/members/add';
  // const url = 'http://localhost:27017/members/add';

  //Add new member to collection
  const addNewMember = async () => {
    try {
      let firstName = document.getElementById("first-name").value;
      let lastName = document.getElementById("last-name").value;
      
      if ((firstName.value == '') || (lastName.value == '')) {
        console.log('one field is blank');
        return;
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }),
        body: JSON.stringify({firstName: firstName, lastName: lastName})
      });

      //Update new member status state data
      props.refresh();

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div id="add-member-container">
      <input id="first-name" placeholder="first name" />
      <input id="last-name" placeholder="last name"/>
      <button id="new-member-submit" onClick={addNewMember}>add</button>
    </div>
  )
}

export default AddMember;
